import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="container">
      <h1>Not Found</h1>
      <p>
        The page you are looking for does not exist. Return to{" "}
        <Link to="/">home</Link>.
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
